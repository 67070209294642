<template>
  <div>
    <el-row class="bgW">
      <el-col :span="21">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="店铺名称" v-if="!userInfo.shop_id">
            <el-select
              v-model="search.shop_id"
              clearable
              remote
              filterable
              :remote-method="remoteShop"
              placeholder="请输入店铺名称"
              class="w200"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>
           <el-form-item label="日期">
            <el-date-picker
              v-model="search.start_time"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="search.end_time"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          
        </el-form>
      </el-col>
      <el-col :span="3" class="btnBox">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        <el-button type="primary"  size="mini"  :loading="exportS" @click="exportExcel">导出数据</el-button>
      </el-col>
    </el-row>

    
        
        
      
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="顾客姓名" prop="consignee" />
            <ElTableColumn label="联系方式" prop="telephone" />
            <ElTableColumn label="赠送产品品名" prop="goods_name" />
            <ElTableColumn label="优惠金额" prop="price" />
          </el-table>
          <div class="reveal">
            <span class="mr20">
              金额合计：
              <span class="peril">￥{{price}}</span>
            </span>
          </div>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
   
  </div>
</template>

<script>
import { getLastDay } from '@/utils';
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import { handleExportExcel } from "@/vendor/handleExportExcel";
import { getShopList } from "@/api/shop";
import { discountDetails } from "@/api/statistics";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  shop_id = "";
  start_time = "";
  end_time = "";
}
class Options {
  shop_id = [];
}
export default {
  name: "OrderList",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(), // 检索条件
      options: new Options(), 
      page: new Page(), // 分页
      list: [], // 数据展示
      idList: [], // 导出数据
      price: "",
      exportS: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if(month.toString().length == 1){
      month = '0'+ month
    }
    let day = getLastDay(year,month)
    if(day.toString().length == 1){
      day = '0'+ day
    }
    this.search.start_time = year+'-'+ month + '-01' 
    this.search.end_time = year+'-'+ month + '-' + day
    this.getList('search');
    
  },
  methods: {
     
    getList(option) {
      if (option === "search") this.page = new Page();
      discountDetails({ ...this.search, ...this.page }).then(res => {
        this.list = res.data.list;
        this.page.total = res.data.dataCount;
        this.price = res.data.total_price;
      });
    },
    remoteShop(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shop_id = res.list;
      });
    },
     // 导出表格
    async exportExcel() {
      this.exportS = true
      const { data } = await discountDetails({
         ...this.search, ...this.page
      });
      handleExportExcel(
        [
          '顾客姓名',
          '联系方式',
          '赠送产品品名',
          '优惠金额'
        ],
        [
          'consignee',
          'telephone',
          'goods_name',
          'price'
        ],
        data.list,
        '优惠明细'
      );
      this.exportS = false
    },
  }
};
</script>

<style lang="scss" scoped>
.reveal {
  text-align: right;
  padding: 20px 0;
  .peril {
    font-weight: bold;
  }
}
.btnBox{
  display: flex;
  justify-content: flex-end;
  .el-button{
    height: 28px;
  }
}
</style>
